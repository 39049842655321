<template>
  <div class="service-design-row">
    <div class="container container--inherit-height">
      <div class="col left">
        <div class="col__layer">
          <BaseImage
            class="image"
            src="serviceDesignImage.png"
          />
        </div>
      </div>
      <div class="col right">
        <div class="col__layer content-text">
          <h2 class="h3">
            Enterprise
          </h2>
          <p class="h4">
            Solutions designed to align seamlessly with your organization's unique goals, requirements, and vision.
          </p>
          <ul class="ul">
            <li class="li">
              <div class="item-marker" />
              <p class="text">
                <strong>Tailored Infrastructure Expertise:</strong>
                <br>
                Collaborate closely with our team to customize a reference architecture, crafting a robust and well-architected infrastructure tailored to your organization's needs. Ensure optimal performance and seamless integration into your existing ecosystem.
              </p>
            </li>
            <li class="li">
              <div class="item-marker" />
              <p class="text">
                <strong>Iterative Refinement and Collaboration:</strong>
                <br>
                Unlike generic approaches, our Enterprise Track involves weekly check-ins and updates. Our DevOps experts collaboratively refine design decisions, delving deep into security, compliance, workload migration, and customization. This iterative process empowers your team to excel in the dynamic DevOps landscape.
              </p>
            </li>
            <li class="li">
              <div class="item-marker" />
              <p class="text">
                <strong>Excellence in Performance, Collaboration, and Innovation:</strong>
                <br>
                Elevate team performance, foster collaboration, and drive innovation with the DevOps Excellence Enterprise Track. It represents the epitome of customization and co-creation for enduring success in your DevOps journey.
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {BaseImage} from '@/components/global'

export default {
  name: 'ServiceDesignRow',
  components: {BaseImage},
  props: {}
};
</script>

<style lang="scss" scoped>
.service-design-row {
  height: 550px;
  margin: 80px 0;


  .image {
    height: inherit;
  }

  .col.left {
    @include grid-column(5);
  }

  .col.right {
    @include grid-column(4);
    @include grid-push(2);

    .col__layer {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      text-align: left;
    }
  }
}
</style>