import Vue from 'vue';
import Router from 'vue-router';
import Home from './views/Home';
import Services from './views/Services';
import About from './views/About';
import Contact from './views/Contact';
import Test from './views/Test';

Vue.use(Router);

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [{
    path: '/',
    name: 'home',
    component: Home,
  },
  {
    path: '/services',
    name: 'services',
    component: Services
  },
  {
    path: '/about',
    name: 'about',
    component: About
  },
  {
    path: '/contact',
    name: 'contact',
    component: Contact
  },
  {
    path: '/test',
    name: 'test',
    component: Test
  },
  ],
  scrollBehavior() {
    return {
      x: 0,
      y: 0
    }
  }
});