<template>
  <div class="lets-build-row">
    <div class="container">
      <div class="col">
        <div class="row">
          <h2 class="h3">
            Let's build something great together
          </h2>
        </div>
        <div class="row">
          <router-link
            :to="{name: 'services'}"
            class="link"
          >
            &#62;&nbsp;Our Services
          </router-link>
          <router-link
            :to="{name: 'contact'}"
            class="link"
          >
            &#62;&nbsp;Contact
          </router-link>
        </div>
      </div>
      <div class="col">
        <p class="text">
          As your business undergoes growth, it becomes paramount to proactively manage your infrastructure, empowering you to navigate unforeseen challenges and seize emerging opportunities. Transform this ability to adapt into a strategic advantage, ensuring the stability and success of your organization in a dynamic business landscape.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LetsBuildRow',
  props: {},
};
</script>

<style lang="scss" scoped>
.lets-build-row {
  margin: 175px 0;

  @include md {
    height: auto;
    margin: 20px 0;
  }

  .col {
    @include grid-column(4);

    text-align: left;
    margin-bottom: 20px;

    @include md {
      @include grid-column(8);
      @include grid-push(2);
    }
  }

  .col:first-of-type {
    @include grid-push(2);

    @include md {
      @include grid-column(8);
      @include grid-push(2);
    }

    .row:first-of-type {
      margin-bottom: 20px;
    }

    .link {
      text-decoration: none;
      margin-right: 40px;
    }
  }
}
</style>
