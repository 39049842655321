import Vue from 'vue';
import VueTypedJs from 'vue-typed-js'
import App from './App.vue';
import Hamburger from 'vue-hamburger';
import router from './router';
import './registerServiceWorker';

Vue.config.productionTip = false;

Vue.use(VueTypedJs)

Vue.component('Hamburger', Hamburger)

new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app');