<template>
  <div class="home">
    <HeroRow />
    <!-- <ProjectGridRow /> -->
    <LetsBuildRow />
    <!-- <MeetTheTeamRow />
    <OurClientsRow /> -->
  </div>
</template>

<script>
import {
  HeroRow, LetsBuildRow, MeetTheTeamRow, ProjectGridRow, OurClientsRow,
} from '@/components/rows';

export default {
  name: 'Home',
  components: {
    HeroRow, LetsBuildRow, MeetTheTeamRow, OurClientsRow, ProjectGridRow,
  },
};
</script>

<style lang="scss" scoped>


</style>
