<template>
  <div class="base-button">
    <button
      v-if="!link && !routerLink"
      v-bind="$attrs"
      :class="{ primary, gradient, white, ghost }"
      class="core-button"
      v-on="$listeners"
    >
      <slot />
    </button>
    <a
      v-if="link"
      v-bind="$attrs"
      :class="{ primary, gradient, white, ghost }"
      class="core-button"
      v-on="$listeners"
    >
      <slot />
    </a>
    <router-link
      v-if="routerLink"
      v-bind="$attrs"
      :class="{ primary, gradient, white, ghost }"
      class="core-button"
      v-on="$listeners"
    >
      <slot />
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'BaseButton',
  props: {
    primary: {
      type: Boolean,
      default: true,
    },
    gradient: {
      type: Boolean,
      default: false,
    },
    white: {
      type: Boolean,
      default: false,
    },
    ghost: {
      type: Boolean,
      default: false,
    },
    link: {
      type: Boolean,
      default: false,
    },
    routerLink: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.base-button {
  .core-button {
    @extend .h4;

    background: none;
    border: 0;
    height: 50px;
    min-width: 180px;
    padding: 0 20px;
    line-height: 50px;
    background-color: red;
    display: inline-block;
    cursor: pointer;
    text-decoration: none;
    border-radius: 5px;
    text-align: center;

    &.primary {
      background-color: $purpleLight;
      color: $white;
      box-shadow: 0 4px 8px rgba($purpleLight, 0.2);
    }

    &.gradient {
      background: $purpleMediumToGreen;
      color: $white;
      box-shadow: 0 4px 8px rgba($purpleLight, 0.2);
    }

    &.white {
      background-color: $white;
      color: $purpleMedium;
      box-shadow: 0 4px 8px rgba($purpleLight, 0.2);
    }

    &.ghost {
      background-color: transparent;
      border: solid 4px $white;
      color: $white;
      line-height: 42px;
    }
  }
}
</style>
