<template>
  <div class="service-other-row">
    <div class="container container--inherit-height">
      <div class="col col--1-3">
        <div class="col__layer">
          <BaseImage
            class="image"
            src="pyramid.png"
          />
        </div>
      </div>
      <div class="col col--2-3 right">
        <div class="col__layer content-text">
          <h2 class="h3">
            Need something else?
          </h2>
          <p class="text">
            <strong>ioLab's</strong> AWS Blueprints for Terraform provide turnkey solutions, akin to prefab houses, ensuring quick and predictable success in AWS. Leveraging our proven AWS reference architecture, we facilitate rapid implementation, reducing risk and enhancing predictability for your team.
            Why start from scratch? Our ready-to-go blueprints, based on common architecture patterns, are customizable to meet your specific needs. As a DevOps Accelerator, we prioritize a process that accelerates your team's pace with minimal risk.
          </p>
          <div class="row">
            <div class="sub-col">
              <h5 class="h5">
                Give us a call
              </h5>
              <a
                class="h5 link"
                href="tel:0031619935185"
              >+31 619 935 185</a>
            </div>
            <div class="sub-col">
              <h5 class="h5">
                Send us an email
              </h5>
              <a
                class="h5 link"
                href="mailto:info@iolab.nl"
              >info@iolab.nl</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {BaseImage} from '@/components/global'

export default {
  name: 'ServiceOtherRow',
  components: {BaseImage},
  props: {}
};
</script>

<style lang="scss" scoped>
.service-other-row {
  background-color: rgba($purpleLight, 0.1);
  height: 500px;

  .image {
    height: inherit;
  }

  .col__layer {
    padding-top: 125px;
    padding-bottom: 125px;
  }

  .right {
    text-align: left;
  }

  .row {
    display: flex;
  }

  .sub-col {
    margin-top: 40px;
    text-align: left;
    flex: 1 0 50%;

    .h5 {
      line-height: 1em;
      margin-bottom: 4px;
    }

    .link {
      text-decoration: none;
      color: $purpleLight;
    }
  }
}
</style>
