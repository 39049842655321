<template>
  <div class="service-web-row">
    <div class="container container--inherit-height">
      <div class="col left">
        <div class="col__layer content-text">
          <h2 class="h3">
            Kickstart
          </h2>
          <p class="h4">
            Tailored for startups and SMBs aiming to swiftly adopt AWS best practices, as outlined in the AWS Well-Architected framework.
          </p>
          <ul class="ul">
            <li class="li">
              <div class="item-marker" />
              <p class="text">
                <strong>Continuous Updates and Open Source Foundation:</strong>
                <br>
                Benefit from a continuously updated solution by ioLab, built upon our open-source materials. Stay current with the latest industry standards and best practices.
              </p>
            </li>
            <li class="li">
              <div class="item-marker" />
              <p class="text">
                <strong>Tailored for Varied Needs:</strong>
                <br>
                Ideal for companies with straightforward architectural requirements or those adept with AWS and Terraform for self-customization. Professional Services are available for additional customizations or workload migrations beyond the scope of Jumpstart.
              </p>
            </li>
            <li class="li">
              <div class="item-marker" />
              <p class="text">
                <strong>Perfect for Scalable Deployments:</strong>
                <br>
                Suited for companies deploying numerous services and backing services, leveraging AWS managed services like ECS, EKS, RDS, MSK, Airflow, etc. Ideal for those seeking rapid provisioning assistance while maintaining the autonomy to handle the rest independently.
              </p>
            </li>
          </ul>
        </div>
      </div>
      <div class="col right">
        <div class="col__layer">
          <BaseImage
            class="image"
            src="serviceWebImage.png"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {BaseImage} from '@/components/global'

export default {
  name: 'ServiceWebRow',
  components: {BaseImage},
  props: {}
};
</script>

<style lang="scss" scoped>
.service-web-row {
  height: 550px;
  margin: 80px 0;


  .image {
    height: inherit;
  }

  .col.left {
    @include grid-column(4);
    @include grid-push(1);

    .col__layer {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      text-align: left;
    }
  }

  .col.right {
    @include grid-column(5);
    @include grid-push(1);
  }
}
</style>