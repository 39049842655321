<template>
  <div class="service-cloud-row">
    <div class="container container--inherit-height">
      <div class="col left">
        <div class="col__layer content-text">
          <h2 class="h3">
            Professional
          </h2>
          <p class="h4">
            As a specialized DevOps Accelerator, ioLab propels customers with a comprehensive accelerator experience through proven DevOps methodologies and best practices.
          </p>
          <ul class="ul">
            <li class="li">
              <div class="item-marker" />
              <p class="text">
                <strong>Tailored Professional Services:</strong>
                <br>
                Receive high-touch professional services customized to your specific needs, including Terraform module customization, documentation creation, system implementation, integrations (e.g., Okta), infrastructure upgrades, hands-on support, and remote sessions.
              </p>
            </li>
            <li class="li">
              <div class="item-marker" />
              <p class="text">
                <strong>Next-Stage Growth Expertise:</strong>
                <br>
                Explore advanced offerings in Security, Compliance, Release Engineering, or Site Reliability Engineering as your organization progresses to the next stage of growth. Many customers return for these specialized services.
              </p>
            </li>
            <li class="li">
              <div class="item-marker" />
              <p class="text">
                <strong>Optimal Support with Our Terraform Modules:</strong>
                <br>
                Benefit from our appreciation for your use of our Terraform Modules. Our support is aligned with our reference architecture, employing a prescriptive, repeatable approach using our component library and atmos tooling. While diverse approaches exist, our focus ensures optimal support within our repeatable process.
              </p>
            </li>
          </ul>
        </div>
      </div>
      <div class="col right">
        <div class="col__layer">
          <BaseImage
            class="image"
            src="serviceCloudManagmentImage.png"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {BaseImage} from '@/components/global'

export default {
  name: 'ServiceCloudRow',
  components: {BaseImage},
  props: {}
};
</script>

<style lang="scss" scoped>
.service-cloud-row {
  height: 550px;
  margin: 80px 0;

  .image {
    height: inherit;
  }

  .col.left {
    @include grid-column(4);
    @include grid-push(1);

    .col__layer {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      text-align: left;
    }
  }

  .col.right {
    @include grid-column(5);
    @include grid-push(1);
  }
}
</style>