<template>
  <div class="contact">
    <div class="row row--head">
      <div class="container">
        <div class="col col--1-2 col--left">
          <div class="col__layer content-text">
            <h1 class="h1">
              Contact us
            </h1>
            <p class="h2">
              Wondering how to navigate the complexities of building and managing your cloud infrastructure without the headaches?
              <br>
              Look no further!
              <br>
              <!-- – our DevOps Accelerator is the solution you've been searching for. -->

              <!-- Say Goodbye to Guesswork
              We take the uncertainty out of the equation. Our DevOps Accelerator provides a clear path to constructing and owning your cloud infrastructure with precision.
              On Time, On Budget
              Achieve a predictable outcome, delivered on time and within budget. No surprises, just results you can rely on.

              No Commitments, No Fees, No Strings
              We believe in flexibility. No long-term commitments, no license fees, and absolutely no strings attached. We're here for you, for as long as you need us.

              Your Infrastructure, Your Way
              We don't just build it; we empower you to own it. Using Infrastructure as Code, we implement everything you need on your cloud platform.

              Fast-Track to Success
              In just a few months, you'll achieve the infrastructure you've been envisioning. We guide you every step of the way, ensuring a smooth journey.

              Total Control
              Customize your infrastructure to meet your unique requirements. It's your platform – drive it the way you want.

              Prepare for Growth
              As your business expands, taking control of your infrastructure is paramount. Adapt to challenges and seize new opportunities to stay ahead of the competition.

              Elevate Your Infrastructure Strategy
              It's time to take your infrastructure strategy to new heights. At Cloud Posse, we've helped numerous companies swiftly and efficiently achieve their cloud infrastructure and platform goals. Our specialized expertise and focused mission, known as SweetOps™, guarantee that your business is not just ready for growth but positioned for success, overcoming challenges with confidence. -->
            </p>
          </div>
        </div>
        <div class="col col--1-2">
          <div class="col__layer" />
        </div>
      </div>
    </div>
    <br>
    <br>

    <div class="row row--map">
      <div class="container">
        <div class="col col--1-3 col--left">
          <div class="col__layer">
            <h3 class="h5">
              Just give us a call
            </h3>
            <h4 class="h4 link">
              <a
                class="h5 link"
                href="tel:0031619935185"
              >+31 619 935 185</a>
            </h4>
            <h3 class="h5">
              Send us mail
            </h3>
            <h4 class="h4 link">
              <a
                class="h5 link"
                href="mailto:info@iolab.nl"
              >info@iolab.nl</a>
            </h4>
            <h3 class="h5">
              Let's meet for a cup of coffee
            </h3>
            <h4 class="h4">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d9751.07593180575!2d4.874969849999999!3d52.338336950000006!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c60a05261dd08d%3A0x9eb897c9d7622638!2sZuidas%2C%20Amsterdam!5e0!3m2!1sen!2snl!4v1699978516225!5m2!1sen!2snl"
                width="600"
                height="450"
                style="border: 0;"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              />
            </h4>
          </div>
        </div>
        <div class="col col--1-3">
          <div class="col__layer" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Contact',
  props: {}
};
</script>

<style lang="scss" scoped>
.contact {
  padding-top: 120px;

  .row.row--head {
    margin: 50px 0;
    height: 200px;

    .col {
      &--left {
        text-align: left;
      }

      &--right {
        text-align: right;
      }
    }
  }

  .row.row--map {
    height: 500px;

    .col--left {
      text-align: left;

      .link {
        margin-bottom: 50px;
      }
    }
  }
}
</style>