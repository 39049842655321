<template>
  <div
    :class="{ 'not-on-top': scrollPosition > 0, 'not-home': $route.name !== 'home' }"
    class="navbar"
  >
    <div class="container container--inherit-height">
      <div class="col col--full">
        <div class="layer wrapper">
          <div class="col col--center">
            <LogoImage
              class="logo"
              @click.native="$router.push({ name: 'home' })"
            />
          </div>
          <div class="col col--center">
            <ul class="menu-items only-show-on-desktop">
              <li>
                <router-link
                  :to="{ name: 'home' }"
                  class="h5 menu-item"
                >
                  Home
                </router-link>
              </li>
              <li>
                <router-link
                  :to="{ name: 'services' }"
                  class="h5 menu-item"
                >
                  Services
                </router-link>
              </li>
              <!-- <li>
                <router-link
                  :to="{ name: 'about' }"
                  class="h5 menu-item"
                >
                  About me
                </router-link>
              </li> -->
              <!-- <li>
                <router-link
                  :to="{ name: 'test' }"
                  class="h5 menu-item"
                >
                  Test
                </router-link>
              </li> -->
              <li>
                <BaseButton
                  :to="{ name: 'contact' }"
                  primary
                  router-link
                >
                  Contact
                </BaseButton>
              </li>
            </ul>
            <MobileSidebar class="only-show-on-mobile" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BaseButton, MobileSidebar } from '.';
import { LogoImage } from '@/assets/images';

export default {
  name: 'Navbar',
  components: {
    LogoImage,
    BaseButton,
    MobileSidebar,
  },
  props: {},
  data: function () {
    return {
      scrollPosition: 0
    };
  },
  created() {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      this.scrollPosition = window.pageYOffset;
    }
  }

};
</script>

<style lang="scss" scoped>
.navbar {
  height: 140px;
  display: flex;
  justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 999;
  transition: background-color ease-in-out 0.3s;

  .logo {
    cursor: pointer;
  }

  .only-show-on-mobile {
    display: none;
  }

  @include md {
    height: 80px;
    box-shadow: 0 2px 18px -2px rgba($purpleDark, 0.3);
    background-color: $white;

    .only-show-on-mobile {
      display: block;
    }

    .only-show-on-desktop {
      display: none !important;
    }
  }
}

.navbar.not-on-top {
  background-color: $white;
  box-shadow: 0 2px 18px -2px rgba($purpleDark, 0.3);

  .menu-item {
    color: $purpleDark;
  }
}

.navbar.not-home {
  .menu-item {
    color: $purpleDark;
  }
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: inherit;
}

.menu-items {
  display: flex;
}

.menu-item {
  color: $white;
  margin-right: 50px;
  text-decoration: none;
  line-height: 50px;
  transition: color ease-in-out 0.3s;
}
</style>
