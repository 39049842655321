import { render, staticRenderFns } from "./OurClientsRow.vue?vue&type=template&id=8f14f1a4&scoped=true&"
import script from "./OurClientsRow.vue?vue&type=script&lang=js&"
export * from "./OurClientsRow.vue?vue&type=script&lang=js&"
import style0 from "./OurClientsRow.vue?vue&type=style&index=0&id=8f14f1a4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8f14f1a4",
  null
  
)

export default component.exports