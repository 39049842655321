<template>
  <svg 
    width="160px" 
    height="46px" 
    viewBox="0 0 160 46" 
    version="1.1" 
    xmlns="http://www.w3.org/2000/svg" 
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >

    <g 
      id="Website" 
      stroke="none" 
      stroke-width="1" 
      fill="none" 
      fill-rule="evenodd" 
      opacity="0.5"
    >
      <g 
        id="Home" 
        transform="translate(-641.000000, -2949.000000)" 
        fill="#251F4D"
      >
        <g 
          id="Group-3" 
          transform="translate(641.000000, 2947.000000)"
        >
          <g 
            id="logo02" 
            transform="translate(0.000000, 2.000000)"
          >
            <path 
              id="Fill-76" 
              d="M98.7550553,35.7043084 C89.4709414,42.0983289 76.0088714,45.5 64.417872,45.5 C48.1718948,45.5 33.5434612,39.8965959 22.4727857,30.5675831 C21.6039496,29.8356274 22.3791972,28.8338324 23.4240354,29.401538 C35.3677376,35.8874603 50.1379506,39.7929619 65.3935665,39.7929619 C75.6841055,39.7929619 86.9971332,37.8011039 97.4057054,33.6798612 C98.9757565,33.0587091 100.291582,34.6464598 98.7550553,35.7043084"
            />
            <path 
              id="Fill-77" 
              d="M103.075602,31.5167184 C101.89618,30.1527449 95.2814372,30.8707576 92.3103497,31.1915451 C91.4103563,31.288032 91.2709891,30.5794174 92.0822312,30.064403 C97.3587716,26.7149302 106.0037,27.6829316 107.009086,28.8025552 C108.021405,29.9347096 106.744219,37.7664362 101.799109,41.5049892 C101.037789,42.0801512 100.314605,41.7731475 100.652276,41.0144099 C101.763747,38.5032451 104.255716,32.8813184 103.075602,31.5167184"
            />
            <path 
              id="Fill-78" 
              d="M92.4834392,5.44447248 L92.4834392,2.010817 C92.4862613,1.48846232 92.9067541,1.14000547 93.4119099,1.14000547 L109.835117,1.13934426 C110.360733,1.14000547 110.781931,1.49771924 110.783342,2.00684975 L110.781931,4.95187219 C110.777698,5.4457949 110.333923,6.09047314 109.545852,7.11402382 L101.038633,18.4967273 C104.196562,18.4266392 107.536517,18.8696489 110.405181,20.3811714 C111.052853,20.7216937 111.226412,21.226857 111.277209,21.7214409 L111.277209,25.3858581 C111.277209,25.889699 110.688096,26.4748685 110.068645,26.1713738 C105.01356,23.6891973 98.3054301,23.4181018 92.7162624,26.2011282 C92.1447873,26.4867702 91.5472078,25.9101964 91.5472078,25.4056944 L91.5472078,21.9217871 C91.5472078,21.3650496 91.5584962,20.411587 92.1581923,19.5619341 L102.013669,6.31065555 L93.4330757,6.30933314 C92.9081652,6.30933314 92.4876724,5.95889266 92.4834392,5.44447248"
            />
            <path 
              id="Fill-79" 
              d="M32.6343188,26.2770492 L27.7132097,26.2770492 C27.2455584,26.2476568 26.8710204,25.9171558 26.8328023,25.4945588 L26.8362767,1.75531519 C26.8362767,1.27981183 27.2615405,0.900323582 27.7861716,0.900323582 L32.3688763,0.899017254 C32.8483405,0.921877992 33.2333016,1.26348274 33.2638761,1.69653043 L33.2638761,4.79579335 L33.3555997,4.79579335 C34.5500909,1.79842401 36.800793,0.4 39.8325353,0.4 C42.9101394,0.4 44.8391141,1.79842401 46.2177473,4.79579335 C47.4115436,1.79842401 50.1215585,0.4 53.0150206,0.4 C55.0829704,0.4 57.3336726,1.1988195 58.710916,2.99763301 C60.2729964,4.99566152 59.9519639,7.89244362 59.9519639,10.4410893 L59.9484895,25.4220576 C59.9484895,25.8956014 59.5225308,26.2770492 58.9985945,26.2770492 L54.0837393,26.2770492 C53.5903776,26.2463505 53.2005524,25.8786192 53.2005524,25.4233639 L53.1991627,12.8388542 C53.1991627,11.8395133 53.2908862,9.34181441 53.0615773,8.3927672 C52.6939881,6.79447502 51.5919154,6.34444507 50.1681152,6.34444507 C48.973624,6.34444507 47.7332711,7.09427727 47.2274016,8.29283311 C46.722227,9.49204212 46.7687837,11.4894175 46.7687837,12.8388542 L46.7687837,25.4220576 C46.7687837,25.8956014 46.3435198,26.2770492 45.8195836,26.2770492 L40.9040335,26.2770492 C40.4106718,26.2463505 40.0208466,25.8786192 40.0208466,25.4233639 L40.0159825,12.8388542 C40.0159825,10.1915807 40.4752953,6.29480461 36.984935,6.29480461 C33.4473233,6.29480461 33.5856035,10.0909935 33.5856035,12.8388542 L33.5835189,25.4220576 C33.5842138,25.8956014 33.158255,26.2770492 32.6343188,26.2770492"
            />
            <path 
              id="Fill-80" 
              d="M123.859356,5.1540259 C120.266647,5.1540259 120.039652,9.71132907 120.039652,12.5538764 C120.039652,15.3964238 119.994941,21.4736824 123.813957,21.4736824 C127.588261,21.4736824 127.769857,16.5729154 127.769857,13.583627 C127.769857,11.6228076 127.678371,9.2698243 127.042786,7.40768665 C126.496623,5.79097181 125.404984,5.1540259 123.859356,5.1540259 Z M123.813957,0.4 C131.044086,0.4 134.953211,6.1831357 134.953211,13.5342861 C134.953211,20.6406545 130.634807,26.2770492 123.813957,26.2770492 C116.720712,26.2770492 112.85561,20.4939135 112.85561,13.2888633 C112.85561,6.03575385 116.766111,0.4 123.813957,0.4 Z"
            />
            <path 
              id="Fill-81" 
              d="M144.780485,26.2770492 L139.804294,26.2770492 C139.30343,26.2463505 138.906972,25.8786192 138.906972,25.4233639 L138.899212,1.67497602 C138.941538,1.24127516 139.355633,0.900323582 139.859319,0.900323582 L144.49337,0.899017254 C144.929333,0.919265336 145.289109,1.19555369 145.380816,1.56459131 L145.380816,5.19487652 L145.475345,5.19487652 C146.874238,1.94865171 148.832546,0.4 152.282159,0.4 C154.520527,0.4 156.713042,1.14917905 158.111934,3.19750118 C159.418414,5.0955956 159.418414,8.29283311 159.418414,10.5906639 L159.418414,25.5291765 C159.361978,25.9504672 158.954233,26.2770492 158.461128,26.2770492 L153.452488,26.2770492 C152.989717,26.2489631 152.617244,25.9334849 152.562925,25.5291765 L152.562925,12.638986 C152.562925,10.0406998 152.889545,6.24451098 149.438521,6.24451098 C148.22516,6.24451098 147.106328,6.99369003 146.547618,8.14260541 C145.847819,9.59132304 145.753995,11.0400407 145.753995,12.638986 L145.753995,25.4220576 C145.74553,25.8956014 145.313799,26.2770492 144.780485,26.2770492"
            />
            <path 
              id="Fill-86" 
              d="M84.3025198,25.8972566 C83.9738133,26.1657968 83.502434,26.1843389 83.1317649,26.0033939 C81.4875327,24.7527636 81.1923961,24.1754021 80.2936984,22.9848737 C77.5745257,25.5174639 75.6491444,26.2770492 72.1277878,26.2770492 C67.95811,26.2770492 64.7144055,23.9241252 64.7144055,19.2201953 C64.7144055,15.5437515 66.8922614,13.0444091 69.9960907,11.8187147 C72.6837915,10.7407174 76.4366414,10.5450667 79.3089774,10.2509512 L79.3089774,9.66272019 C79.3089774,8.58472293 79.401295,7.31043555 78.7068149,6.37949605 C78.1046525,5.54574253 76.945787,5.20303403 75.9260972,5.20303403 C74.0377828,5.20303403 72.3585818,6.08793807 71.9459501,7.92232367 C71.8599269,8.3296097 71.5347172,8.73305945 71.085718,8.75415904 L66.2852033,8.27909856 C65.8795654,8.19533958 65.4291675,7.89866655 65.5459632,7.3328139 C66.649578,2.00868392 71.9081839,0.4 76.621976,0.4 C79.0313253,0.4 82.1820127,0.988231005 84.0815171,2.65445927 C86.4908664,4.7126284 86.2586736,7.4574933 86.2586736,10.4472413 L86.2586736,17.5015377 C86.2586736,19.6230056 87.2231127,20.5539451 88.1288042,21.6977987 C88.4442227,22.1095604 88.515559,22.5993266 88.1120192,22.899836 C87.1000226,23.6754057 85.3026271,25.1031447 84.3137098,25.9074867 L84.3025198,25.8972566 Z M79.3089774,14.8576951 C79.3089774,16.6223881 79.3551362,18.0923262 78.3823046,19.6607291 C77.5941082,20.9356559 76.3436245,21.7188983 74.953965,21.7188983 C73.0537612,21.7188983 71.9417539,20.3947391 71.9417539,18.4356741 C71.9417539,14.5795641 75.7253764,13.8775232 79.3089774,13.8775232 L79.3089774,14.8576951 Z"
            />
            <path 
              id="Fill-87" 
              d="M19.5881787,25.8972566 C19.2601704,26.1657968 18.7873909,26.1843389 18.4181193,26.0033939 C16.7724829,24.7527636 16.4780448,24.1754021 15.5786447,22.9848737 C12.8601625,25.5174639 10.9340755,26.2770492 7.41340669,26.2770492 C3.24301574,26.2770492 0,23.9241252 0,19.2201953 C0,15.5437515 2.17786306,13.0444091 5.28170258,11.8187147 C7.96871281,10.7407174 11.7222745,10.5450667 14.5946199,10.2509512 L14.5946199,9.66272019 C14.5946199,8.58472293 14.6869378,7.31043555 13.9924554,6.37949605 C13.3895916,5.54574253 12.2314217,5.20303403 11.2124279,5.20303403 C9.32340798,5.20303403 7.64350204,6.08793807 7.23156841,7.92232367 C7.14554492,8.3296097 6.82033415,8.73305945 6.37133348,8.75415904 L1.57080296,8.27909856 C1.16516373,8.19533958 0.714064927,7.89866655 0.831560429,7.3328139 C1.93447952,2.00868392 7.193802,0.4 11.9076096,0.4 C14.3162674,0.4 17.4669653,0.988231005 19.3671753,2.65445927 C21.7758331,4.7126284 21.544339,7.4574933 21.544339,10.4472413 L21.544339,17.5015377 C21.544339,19.6230056 22.5080818,20.5539451 23.4137763,21.6977987 C23.7298952,22.1095604 23.8012317,22.5993266 23.3976906,22.899836 C22.3863901,23.6754057 20.5882892,25.1031447 19.5993688,25.9074867 L19.5881787,25.8972566 Z M14.5946199,14.8576951 C14.5946199,16.6223881 14.6407788,18.0923262 13.667944,19.6607291 C12.8804444,20.9356559 11.6292572,21.7188983 10.2388938,21.7188983 C8.34008252,21.7188983 7.22807152,20.3947391 7.22807152,18.4356741 C7.22807152,14.5795641 11.0117064,13.8775232 14.5946199,13.8775232 L14.5946199,14.8576951 Z"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'ClientLogoTwoSvg',
};
</script>

<style lang="scss" scoped>
