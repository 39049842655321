<template>
  <div class="mobile-sidebar">
    <div>
      <Hamburger
        :stroke="3"
        :gap="6"
        :open.sync="open"
        class="hamburger"
        color="#251F4D"
      />
    </div>
    <div class="backdrop" />

    <div
      :class="{ open }"
      class="sidebar"
    >
      <ul class="menu-items">
        <li>
          <router-link
            :to="{ name: 'home' }"
            class="h5 menu-item"
          >
            Home
          </router-link>
        </li>
        <li>
          <router-link
            :to="{ name: 'services' }"
            class="h5 menu-item"
          >
            Services
          </router-link>
        </li>
        <!-- <li>
          <router-link
            :to="{ name: 'about' }"
            class="h5 menu-item"
          >
            About me
          </router-link>
        </li> -->
        <li>
          <BaseButton
            :to="{ name: 'contact' }"
            ghost
            router-link
            class="contact-button"
          >
            Contact
          </BaseButton>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import BaseButton from './BaseButton';


export default {
  name: 'MobileSidebar',
  components: {
    BaseButton
  },
  data: function () {
    return {
      open: false
    };
  }
};
</script>

<style lang="scss" scoped>
.mobile-sidebar {
  position: relative;

  .hamburger {
  }

  .sidebar {
    position: fixed;
    top: 80px;
    right: 0;
    height: calc(100vh - 80px);
    max-width: 300px;
    width: 80%;
    background-color: $white;
    transform: translateX(100%);
    background-color: $purpleDark;
    transition: transform 0.25s ease-in-out;
    z-index: 999;

    &.open {
      transform: translateX(0);
    }

    .menu-items {
      padding: 50px;
      text-align: left;

      .h5 {
        display: block;
        color: $white;
        margin-bottom: 20px;
        text-decoration: none;
      }

      .contact-button {
        margin-top: 40px;
      }
    }
  }

  .backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    max-width: 100%;
    height: 100vh;
    background: red;
    z-index: 100;
    display: none;
  }
}
</style>