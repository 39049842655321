<template>
  <div class="our-clients-row">
    <div class="container container--inherit-height">
      <div class="col left">
        <div class="col__layer content-text">
          <h3 class="h3">
            Some of our clients
          </h3>
          <p
            class="text"
          >
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Quod natus eum deleniti, impedit earum et molestiae eligendi iste vero, expedita explicabo libero praesentium sunt odio ut soluta iusto est eaque.
          </p>
        </div>
      </div>
      <div class="col right">
        <div class="col__layer">
          <div class="client-col">
            <ClientLogoOne class="client-logo" />
            <ClientLogoTwo class="client-logo" />
          </div>
          <div class="client-col">
            <ClientLogoTwo class="client-logo" />
            <ClientLogoThree class="client-logo" />
          </div>
          <div class="client-col">
            <ClientLogoThree class="client-logo" />
            <ClientLogoOne class="client-logo" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ClientLogoOne, ClientLogoTwo, ClientLogoThree } from '@/assets/images';

export default {
  name: 'OurClientsRow',
  components: { ClientLogoOne, ClientLogoTwo, ClientLogoThree },
  props: {},
};
</script>

<style lang="scss" scoped>
.our-clients-row {
  background-color: rgba($purpleLight, 0.1);
  height: 500px;

  .col.left {
    @include grid-column(4);
    @include grid-push(1);

    .col__layer {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      text-align: left;
    }
  }

  .col.right {
    @include grid-column(6);

    .col__layer {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .client-col {
      .client-logo:not(:last-child) {
        margin-bottom: 40px;
      }
    }
  }
}

@include md {
  .our-clients-row {
    background-color: rgba($purpleLight, 0.1);
    height: auto;
    padding: 50px 0 0 0;

    .container {
      height: auto;
    }

    .col.left {
      float: none;
      margin-left: 0;
      width: auto;
      margin-bottom: 40px;
      padding: 0 44px;

      .h3 {
        text-align: center;
      }

      .text {
        display: none;
      }

      .col__layer {
        display: block;
        position: relative;
      }
    }

    .col.right {
      float: none;
      margin-left: 0;
      width: auto;

      .col__layer {
        display: flex;
        flex-wrap: wrap;
        position: relative;
        justify-content: center;
      }

      .client-col {
        display: flex;

        .client-logo {
          margin: 20px 20px;
        }

        .client-logo:not(:last-child) {
          margin: 20px 20px;
        }
      }
    }
  }
}
</style>
