<template>
  <img
    v-if="srcParsed"
    v-bind="$attrs"
    :src="srcParsed"
    :srcset="srcSet"
    class="base-image"
    v-on="$listeners"
  >
</template>

<script>
export default {
  name: 'BaseImage',
  props: {
    baseUrl: {
      type: String,
      default: './img/',
    },
    src: {
      type: String,
      required: true,
    },
    max: {
      type: Number,
      default: 3
    }
  },
  computed: {
    srcParsed() {
      const { baseUrl, src } = this;

      if (!src) {
        return null;
      }

      return `${baseUrl}${src}`;
    },
    srcSet() {
      const { baseUrl, src, max } = this;


      if (!src) {
        return null;
      }

      const [fileName, extention] = src.split('.');

      return ['', '@2x', '@3x'].slice(0, max).map((type, index) => `${baseUrl}${fileName}${type}.${extention} ${index}x`).join(', ');
    },
  },

};
</script>

<style lang="scss" scoped>
.base-image {
  // Insert SCSS here
}
</style>
