<template>
  <div class="service-app-row">
    <div class="container container--inherit-height">
      <div class="col left">
        <div class="col__layer">
          <BaseImage
            class="image"
            src="serviceAppImage.png"
          />
        </div>
      </div>
      <div class="col right">
        <div class="col__layer content-text">
          <h2 class="h3">
            Bootcamp
          </h2>
          <p class="h4">
            Offers an affordable, customizable track tailored to your team's needs.
          </p>
          <ul class="ul">
            <li class="li">
              <div class="item-marker" />
              <p class="text">
                <strong>AWS Reference Architecture for Terraform:</strong>
                <br>
                Utilize our proven AWS reference architecture, equipped with battle-tested configuration templates and expert-recommended default settings to ensure a smooth and seamless deployment process.
              </p>
            </li>
            <li class="li">
              <div class="item-marker" />
              <p class="text">
                <strong>Flexible Learning Experience:</strong>
                <br>
                Take charge of your learning pace with our comprehensive documentation. Enhance your expertise through exclusive customer workshops, fostering collaboration in a supportive environment. Stay connected and receive ongoing support via our dedicated Slack channel, staffed by seasoned DevOps specialists.
              </p>
            </li>
            <li class="li">
              <div class="item-marker" />
              <p class="text">
                <strong>Optimized Operations:</strong>
                <br>
                Achieve operational efficiency with the perfect blend of autonomy, affordability, and expert guidance. Explore additional help or customizations through our available professional services when needed.
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {BaseImage} from '@/components/global'

export default {
  name: 'ServiceAppRow',
  components: {BaseImage},
  props: {}
};
</script>

<style lang="scss" scoped>
.service-app-row {
  height: 550px;
  margin: 80px 0;

  .image {
    height: inherit;
  }

  .col.left {
    @include grid-column(5);
  }

  .col.right {
    @include grid-column(4);
    @include grid-push(1);

    .col__layer {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      text-align: left;
    }
  }
}
</style>