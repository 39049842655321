<template>
  <div class="hero-row">
    <div class="container container--inherit-height">
      <div class="col col--text col--center">
        <div class="content-text">
          <h1 class="h1 h1--accent">
            Hello,
          </h1>
          <h1 class="h1">
            We are ioLab
          </h1>
          <!-- :type-speed="1"
          :back-speed="1"-->
          <vue-typed-js
            :strings="['Adoption', 'Automation', 'Migration', 'Transformation']"
            :loop="true"
            :show-cursor="true"
            :cursor-char="'_'"
            :auto-insert-css="true"
            :start-delay="1000"
            :smart-backspace="true"
            :back-delay="1000"
            :fade-out-delay="500"
          >
            <p class="h2">
              An innovative Cloud Engineering &#38; Architecture Lab, we Strive and Focus on Cloud
              <span
                class="typing h2--accent"
              />.
            </p>
          </vue-typed-js>
          <BaseButton 
            :to="{ name: 'services' }" 
            router-link
          >
            Our services
          </BaseButton>
        </div>
      </div>
      <div class="col col--image">
        <div class="col__layer">
          <BaseImage 
            class="hero-image" 
            src="hero.png"
          />
          <BaseImage 
            class="app" 
            src="heroApp.png"
          />
          <BaseImage 
            :max="2" 
            class="astronaut" 
            src="heroAstronaut.png"
          />
          <BaseImage 
            :max="2" 
            class="mail" 
            src="heroMail.png"
          />
          <BaseImage 
            :max="2" 
            class="pyramid" 
            src="heroPyramid.png"
          />
          <BaseImage 
            class="stack" 
            src="heroStack.png"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BaseImage, BaseButton } from '@/components/global';

export default {
  name: 'HeroRow',
  components: {
    BaseImage,
    BaseButton,
  },
  props: {},
};
</script>

<style lang="scss" scoped>
.hero-row {
  height: 740px;
  width: 100vw;
  max-width: 100%;
  overflow: hidden;

  @include md {
    height: auto;
    padding: 20px 0;
  }

  .col--text {
    @include grid-column(5);

    @include md {
      @include grid-column(12);
    }
  }

  .col--image {
    @include grid-column(7);

    @include md {
      display: none;
    }

    .col__layer {
      .app {
        position: absolute;
        height: 123px;
        left: 0;
        top: 365px;
        left: 20px;
        animation: float 12s ease-in-out alternate infinite both;
      }

      .astronaut {
        position: absolute;
        height: 185px;
        top: 197px;
        right: 44px;

        @include lg {
          display: none;
        }
      }

      .mail {
        position: absolute;
        height: 113px;
        top: 325px;
        right: -50px;
        animation: float 8s ease-in-out alternate-reverse infinite both;

        @include lg {
          top: 222px;
          right: -158px;
        }
      }

      .pyramid {
        position: absolute;
        height: 102px;
        top: 155px;
        left: 240px;
        animation: float 15s ease-in-out alternate-reverse infinite both;
      }

      .stack {
        position: absolute;
        height: 216px;
        top: 96px;
        left: -100px;
        animation: float 19s ease-in-out alternate infinite both;
      }
    }
  }

  .content-text {
    text-align: left;
    margin-top: 140px;
  }

  .hero-image {
    height: 723px;
    position: absolute;
    left: -190px;
    top: 0;
  }
}
</style>
