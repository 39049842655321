<template>
  <div class="contact">
    <div class="row row--head">
      <div class="container">
        <div class="col col--1-2 col--left">
          <div class="col__layer content-text">
            <h1 class="h1">
              Test
            </h1>
            <p class="h2">
              This is the Test page
            </p>
          </div>
        </div>
        <div class="col col--1-2">
          <div class="col__layer" />
        </div>
      </div>
    </div>
    <div class="row row--map">
      <div class="container">
        <div class="col col--1-3 col--left">
          <div class="col__layer">
            <h3 class="h5">
              Just give us a call
            </h3>
            <h4 class="h4 link">
              number here
            </h4>
            <h3 class="h5">
              Send us mail
            </h3>
            <h4 class="h4 link">
              email here
            </h4>
            <h3 class="h5">
              Come over for some coffee
            </h3>
            <h4 class="h4 link">
              coffee here
            </h4>
          </div>
        </div>
        <div class="col col--1-3" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Test'
};
</script>

<style lang="scss" scoped>
.contact {
  padding-top: 120px;

  .row.row--head {
    margin: 50px 0;
    height: 200px;

    .col {
      &--left {
        text-align: left;
      }

      &--right {
        text-align: right;
      }
    }
  }

  .row.row--map {
    height: 500px;

    .col--left {
      text-align: left;

      .link {
        margin-bottom: 50px;
      }
    }
  }
}
</style>
