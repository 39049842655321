<template>
  <div class="meet-the-team-row">
    <div class="container container--inherit-height">
      <div class="col left">
        <div class="col__layer">
          <BaseImage 
            class="image" 
            src="teamTony.png"
          />
          <BaseImage 
            class="image" 
            src="teamNick.png"
          />
        </div>
      </div>
      <div class="col right">
        <div class="col__layer content-text">
          <h2 class="h3">
            Meet the team
          </h2>
          <p
            class="text"
          >
            Lorem ipsum, dolor sit amet consectetur adipisicing elit. Neque veritatis cumque facilis inventore similique quo vel ducimus aut dolorem dolorum facere nulla officia rem repellendus fuga, soluta nemo quasi non.
          </p>
          <!-- <BaseButton
            :to="{ name: 'about' }"
            router-link
          primary>About us</BaseButton>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BaseButton, BaseImage } from '@/components/global'

export default {
  name: 'MeetTheTeamRow',
  components: { BaseButton, BaseImage },
  props: {},
};
</script>

<style lang="scss" scoped>
.meet-the-team-row {
  text-align: left;
  margin: 100px 0;
  height: 200px;

  @include md {
    margin: 50px 0;
    height: 250px;
  }

  .col.left {
    @include grid-column(6);

    @include md {
      @include grid-column(4);
    }

    .col__layer {
      display: flex;
      align-items: center;

      .image {
        display: block;
        transform: translateY(4px);
        height: 300px;

        @include md {
          height: auto;
          width: 100%;

          &:not(:first-of-type) {
            display: none;
          }
        }
      }
    }
  }

  .col.right {
    @include grid-column(5);
    @include grid-push(1);

    @include md {
      @include grid-column(8);
    }

    .col__layer {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
}
</style>
