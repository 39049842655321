<template>
  <div class="project-grid-row">
    <div class="container container--inherit-height">
      <div class="col col--1-2">
        <div class="row sub-grid">
          <div class="col col--full">
            <div class="panel panel--bg-image panel--top-left" />
          </div>
        </div>
        <div class="row sub-grid">
          <div class="col col--1-2">
            <div class="panel panel--bg-image panel--bottom-left" />
          </div>
          <div class="col col--1-2">
            <div class="panel content-text align-content-center">
              <h2 class="h3">
                See our other work
              </h2>
              <p class="h4">
                An essential part of everyday life. Everything is possible using this
              </p>
              <BaseButton primary>
                Showcase
              </BaseButton>
            </div>
          </div>
        </div>
      </div>
      <div class="col col--1-2">
        <div class="panel panel--bg-image panel--right content-text content-text--all-white align-content-bottom small-content-width">
          <h2 class="h3">
            Calculate or something else
          </h2>
          <p class="h4">
            An essential part of everyday life. Everything is possible using this
          </p>
          <BaseButton ghost>
            View project
          </BaseButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {BaseButton} from '@/components/global'

export default {
  name: 'ProjectGridRow',
  components: {BaseButton},
  props: {},
};
</script>

<style lang="scss" scoped>
$subrow-grid: (
  columns: 12,
  gutter: 22px,
);


.project-grid-row {
  height: 800px;
  margin: 100px 0;

  .sub-grid {
    @include grid-collapse($subrow-grid);
    @include grid-container;
  }

  .row {
    height: (800px / 2) - 11px;

    &:not(:last-child) {
      margin-bottom: 22px;
    }

    .col--1-2 {
      @include grid-column(6, $subrow-grid);
    }

    .col--full {
      @include grid-column(12, $subrow-grid);
    }
  }

  .panel {
    height: inherit;
    padding: 40px;
    display: flex;
    flex-direction: column;


    &.align-content-center {
      align-items: center;
      justify-content: center;
    }

    &.align-content-bottom {
      align-items: center;
      justify-content: flex-end;
    }

    &.small-content-width {
      padding-left: 25%;
      padding-right: 25%;
    }

    &--bg-image {
      background-position: center center;
      background-size: cover;
      box-shadow: 0 4px 10px 0 rgba($purpleLight, 0.2);
    }

    &--top-left {
      background-image: url('../../../public/img/topLeftInProjectGrid.png');
    }

    &--bottom-left {
      background-image: url('../../../public/img/bottomLeftInProjectGrid.png');
    }

    &--right {
      background-image: url('../../../public/img/rightInProjectGrid.png');
    }
  }
}
</style>
