<template>
  <div class="services">
    <div class="header-row">
      <div class="container container--inherit-height">
        <div class="col col--1-2 left">
          <h1 class="h1">
            Services we offer to our client
          </h1>
        </div>
        <div class="col col--1-2 right">
          <div class="col__layer">
            <a
              href=""
              class="text link"
            >Bootcamp</a>
            <div class="vertical-line" />
            <a
              href=""
              class="text link"
            >Kickstart</a>
            <div class="vertical-line" />

            <a
              href=""
              class="text link"
            >Enterprise</a>
            <div class="vertical-line" />

            <a
              href=""
              class="text link"
            >Professional</a>
            <div class="vertical-line" />

            <a
              href=""
              class="text link"
            >Other</a>
          </div>
        </div>
      </div>
    </div>
    <div class="service-app-row">
      <ServiceAppRow />
    </div>

    <div class="service-app-row">
      <ServiceWebRow />
    </div>

    <div class="service-app-row">
      <ServiceDesignRow />
    </div>

    <div class="service-app-row">
      <ServiceCloudRow />
    </div>

    <div class="service-app-row">
      <ServiceOtherRow />
    </div>
  </div>
</template>

<script>
import {
  ServiceCloudRow,
  ServiceAppRow,
  ServiceDesignRow,
  ServiceWebRow,
  ServiceOtherRow
} from '@/components/rows';

export default {
  name: 'Services',
  components: {
    ServiceCloudRow,
    ServiceAppRow,
    ServiceDesignRow,
    ServiceWebRow,
    ServiceOtherRow},
  props: {}
};
</script>

<style lang="scss" scoped>
.services {
  .header-row {
    margin-top: 140px + 50px;
    height: 110px;

    .left {
      text-align: left;
    }

    .right {
      .col__layer {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        font-family: Futura, sans-serif;

        .link {
          margin: 0 12px;
          text-decoration: none;
          font-size: 19px;
          color: rgba($purpleDark, 0.5);


          .link:last-of-type {
            margin-right: 0;
          }
        }

        .vertical-line {
          background-color: rgba($purpleDark, 0.5);
          width: 1px;
          height: 19px;
          transform: translateY(-2px);
        }
      }
    }
  }
}
</style>