<template>
  <div class="footer">
    <div class="layer">
      <BaseImage 
        class="bg-image" 
        src="footerBackground.png"
      />
    </div>
    <div class="layer">
      <div class="container container--inherit-height">
        <div class="col">
          <div class="col__layer content-text content-text--all-white">
            <h2 class="h3">
              Interested in working with us?
            </h2>
            <BaseButton
              :to="{ name: 'contact' }"
              class="contact-us-button"
              primary
              router-link
            >
              Let's talk
            </BaseButton>
          </div>
          <div class="col__layer images-layer">
            <BaseImage 
              class="planet" 
              src="footerPlanet.png"
            />

            <BaseImage 
              class="coffee" 
              src="footerCoffee.png"
            />

            <BaseImage 
              class="laptop" 
              src="footerLaptop.png"
            />

            <BaseImage 
              class="astronaut" 
              src="footerAustronaut.png"
            />

            <BaseImage 
              class="small-planet" 
              src="footerSmallPlanet.png"
            />
            <BaseImage 
              class="stars-one" 
              src="footerStars.png"
            />

            <BaseImage 
              class="stars-two" 
              src="footerStars.png"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BaseImage, BaseButton } from './';

export default {
  name: 'Footer',
  components: { BaseImage, BaseButton },
};
</script>

<style lang="scss" scoped>
.footer {
  position: relative;
  width: 100vw;
  max-width: 100%;
  height: 500px;
  background-color: rgba($purpleLight, 0.1);
  overflow: hidden;

  .layer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    .bg-image {
      width: inherit;
      height: 500px;
    }
  }

  .col__layer {
    padding-top: 140px;

    .contact-us-button {
      z-index: 99;
    }

    &.content-text {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      text-align: center;
      padding-bottom: 120px;
      position: relative;
      z-index: 20px;
    }
  }

  .images-layer {
    .planet {
      height: 171px;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      z-index: 10;
    }

    .coffee {
      height: 206px;
      position: absolute;
      bottom: 185px;
      left: 8px;
      z-index: 10;
      animation: float 15s ease-in-out alternate-reverse infinite both;
    }

    .laptop {
      height: 111px;
      position: absolute;
      top: 90px;
      right: 210px;
      z-index: 10;
      animation: float 12s ease-in-out alternate infinite both;
    }

    .astronaut {
      height: 360px;
      right: 50px;
      bottom: -20px;
      position: absolute;
      transform: rotate(10deg);
      z-index: 10;
      animation: float 19s ease-in-out alternate-reverse infinite both;
    }

    .small-planet {
      position: absolute;
      height: 216px;
      right: 8px;
      bottom: 170px;
    }

    .stars-one {
      position: absolute;
      top: 100px;
      height: 290px;
      transform: translateX(13deg);
      left: 0;
    }

    .stars-two {
      position: absolute;
      top: 290px;
      height: 290px;
      transform: translateX(13deg);
      left: right 0;
    }
  }
}
</style>